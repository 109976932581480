<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="">
    <!--  參數檔案上傳設置跳窗  -->
    <div v-if="showUploadExcelDialogToDB" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center mb-2">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">碳參數Excel資料上傳</h2>
        </div>
        <div>
          <label class="text-2xl block text-o_purple-200 text-sm font-bold mb-2" for="file_input">上傳檔案必須為.xlsx檔案</label>
          <input class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="file_input" type="file" accept=".xlsx" @change="handleParaExcelFileUpload">
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showUploadExcelDialogToDB = !showUploadExcelDialogToDB" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="uploadParaExcelToApp" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            上傳
          </button>
        </div>
      </div>
    </div>
    <!--  方法、欄位說明跳窗  -->
    <div v-if="showMethodColumnIntroDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="w-3/5 h-4/5 flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100">
        <div class="text-left">
          <button @click="showMethodColumnIntroDialog = !showMethodColumnIntroDialog" class="flex-1 px-4 py-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            關閉
          </button>
        </div>
        <div class="flex flex-col items-center text-center mb-2 h-auto overflow-auto tableScrollbar">
<!--          <div class="inline-block p-4 bg-o_purple-100 rounded-full">-->
<!--            <svg class="w-12 h-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>-->
<!--          </div>-->
          <h2 class="mt-2 text-xl font-bold text-o_purple-200">方法欄位說明</h2>
          <p class="mt-5 text-xl font-semibold text-o_purple-100 leading-relaxed text-left" v-html="formattedMethodColumnIntro"></p>
        </div>
      </div>
    </div>
    <!--  提醒廣播設置跳窗  -->
    <div v-if="showNotificationDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="w-3/5 flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100">
        <div class="flex flex-col items-center text-center">
          <div class="inline-block p-4 bg-o_purple-100 rounded-full">
            <svg class="w-12 h-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>
          </div>
          <h2 class="mt-2 text-xl font-semibold text-gray-800">提示訊息</h2>
          <p class="mt-2 text-xl font-semibold text-o_purple-100 leading-relaxed text-left">
            {{ formattedMessage }}
          </p>
        </div>

        <div class="flex items-center mt-3">
          <button @click="showNotificationDialog = !showNotificationDialog" class="flex-1 px-4 py-2 mt-2 mx-10 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
    <!--------------------  提醒廣播設置跳窗  -------------------->
    <div v-if="showNotificationDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="w-3/5 flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100">
        <div class="flex flex-col items-center text-center">
          <div class="inline-block p-4 bg-o_purple-100 rounded-full">
            <svg class="w-12 h-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>
          </div>
          <h2 class="mt-2 text-xl font-semibold text-gray-800">提示訊息</h2>
          <p class="mt-2 text-xl font-semibold text-o_purple-100 leading-relaxed">
            {{ formattedMessage }}
          </p>
        </div>

        <div class="flex items-center mt-3">
          <button @click="showNotificationDialog = !showNotificationDialog" class="flex-1 px-4 py-2 mt-2 mx-10 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
    <!--------------------- 選單與按鈕   --------------------------->
    <div v-show="true" class="bg-gray-50 sticky top-0 z-20">
      <div class="sticky top-0 bg-gray-50 border-b pb-1.5 z-20">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="fixed block relative text-2xl text-o_purple-100 font-semibold mr-2">
            碳計算種類選項:
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="methodSelectActive = !methodSelectActive" type="button"
                      class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ selectMethodState }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
              <!--
                Dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div v-show="methodSelectActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
                <div class="tableScrollbar py-1 max-h-64 overflow-y-auto" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a @click.prevent="methodSelectEvent"
                     v-for="method in methodTypes" :key="method"
                     href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    {{ method }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <label id="listbox-label1" class="ml-2 fixed block relative text-2xl text-o_purple-100 font-semibold mr-2">
            產品選項:
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="productSelectActive = !productSelectActive" type="button"
                      class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ selectProductState }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
              <!--
                Dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div v-show="productSelectActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
                <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a @click.prevent="productSelectEvent"
                     v-for="productOption in productOptions" :key="productOption"
                     href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    {{ productOption }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            @click="computeCarbon(currentSelectMethod, selectProductState)"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            計算
          </button>
          <button
            @click="showMethodColumnIntroDialog=!showMethodColumnIntroDialog"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            說明
          </button>
          <button
            @click="showUploadParaExcelFileDialog"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            上傳
          </button>
          <button
            @click="downLoadExcelDataWithProduct()"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            下載
          </button>
          <button
            @click="downLoadExcelCarbonComputedWithProductMethod(currentSelectMethod, selectProductState)"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            結果
          </button>
          <button
            @click="reloadPage"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            重新整理
          </button>
        </div>
      </div>
    </div>
    <!--------------------- 碳計算結果表單 ------------------->
    <div class="tableScrollbar mx-3 mt-1 custom-table-inside overflow-x-scroll overflow-y-scroll flex flex-col bg-white shadow-2xl rounded-2xl">
      <div class="sm:-mx-6 lg:-mx-8 rounded-2xl">
        <div class="py-0 align-middle inline-block min-w-full sm:pl-6 lg:pl-8">
          <div class="shadow border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-o_purple-100 sticky top-0 z-10">
              <tr>
                <th v-for="(column, columnIndex) in carbonTableColumns" :key="columnIndex.toString() + column" scope="col" class="whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  {{ column }}
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(row, rowIndex) in carbonTableRows" :key="'CBTR' + rowIndex.toString()">
                <td v-for="(rowItem, rowItemIndex) in row" :key="rowItemIndex.toString() + rowItem" class="px-6 py-4 text-sm font-medium text-gray-900">
                  {{ rowItem }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/user.service'

export default {
  name: 'Carbon',
  data () {
    return {
      // 碳計算種類選單
      methodSelectActive: false,
      selectMethodState: '請選擇',
      methodTypes: ['LCA碳排放量', 'LOI土壤有機碳百分比'],
      // 這是實際後端方法對應名稱
      currentSelectMethod: '',
      // 產品作物選單
      productSelectActive: false,
      selectProductState: '請選擇',
      productOptions: [],
      // ----提醒廣播相關--------
      showNotificationDialog: false,
      formattedMessage: '',
      // ----方法、欄位說明相關----
      showMethodColumnIntroDialog: false,
      methodColumnIntro: '',
      // ----碳計算表單相關-------
      carbonTableColumns: ['尚無獲得任何計算'],
      carbonTableRows: [],
      // ----碳參數上傳下載相關----
      showUploadExcelDialogToDB: false,
      selectedParaExcelFile: null
    }
  },
  async mounted () {
    this.methodColumnIntro = ''
    this.showMethodColumnIntroDialog = false
    this.isLoading = true
    await UserService.GetProductList().then(
      res => {
        this.productOptions = res.data.result
      }
    ).catch(err => {
      // console.log('Connection err')
      if (err.response) {
        // Request made and server responded 請求成立且回應有回來，是錯誤情況
        // 若沒有要鎖住畫面則可以註解或清除掉
        this.isLoading = false
        return false
      } else if (err.request) {
        // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
        this.isLoading = false
        return false
      } else {
        // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
        this.isLoading = false
        return false
      }
    })
  },
  methods: {
    handleParaExcelFileUpload (event) {
      this.selectedParaExcelFile = event.target.files[0]
    },
    showUploadParaExcelFileDialog () {
      // 點進來就重新開始，將之前選的檔案清空
      this.selectedParaExcelFile = null
      this.showUploadExcelDialogToDB = !this.showUploadExcelDialogToDB
    },
    async uploadParaExcelToApp () {
      if (!this.selectedParaExcelFile) {
        this.setNotificationDialog('當前尚未選擇任何.xlsx的Excel檔案，無法進行上傳(注意要符合格式)')
        return // 如果没有選擇檔案，則直接返回沒有作用
      }
      this.isLoading = true
      await UserService.UploadCarbonParametersExcel(this.selectedParaExcelFile).then(
        res => {
          const result = res.data
          // console.log('check1 ~~~')
          // console.log(result)
          this.isLoading = false
          if (result.isWorking) {
            this.setNotificationDialog('有其他碳參數Excel檔案上傳運作中，為防止檔案重複，請待其他作業完成再進行批次匯入')
            this.showUploadExcelDialogToDB = false
          } else if (result.is_err) {
            this.setNotificationDialog('碳參數Excel上傳後端出現錯誤，錯誤訊息:' + result.err)
            this.showUploadExcelDialogToDB = false
          } else {
            this.setNotificationDialog('此碳參數Excel檔案成功匯入')
            this.showUploadExcelDialogToDB = false
          }
        }
      ).catch(err => {
        // console.log('Auth err')
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.setNotificationDialog('碳參數Excel檔案上傳做更新標籤請求有成立且有收到回應，但回應狀態為錯誤情況，請回報給管理人員，勿繼續連續上傳')
          this.showUploadExcelDialogToDB = false
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('碳參數Excel檔案上傳做更新標籤請求有成立，但沒有獲得後端伺服回應，請回報給管理人員，勿繼續連續上傳')
          this.showUploadExcelDialogToDB = false
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.setNotificationDialog('碳參數Excel檔案上傳做更新標籤請求建立失敗，請回報給管理人員，勿繼續連續上傳')
          this.showUploadExcelDialogToDB = false
          this.isLoading = false
          return false
        }
      })
    },
    methodSelectEvent () {
      // 下面這個是點擊縮、放選單
      this.methodSelectActive = !this.methodSelectActive
      // 選後把把選擇狀態顯示出來
      this.selectMethodState = event.target.innerText
      if (this.selectMethodState === 'LCA碳排放量') {
        this.currentSelectMethod = 'lca_carbon_footprint'
      } else if (this.selectMethodState === 'LOI土壤有機碳百分比') {
        this.currentSelectMethod = 'organic_carbon_percent'
      }
    },
    async productSelectEvent () {
      // const product = event.target.innerText
      // 下面這個是點擊縮、放選單
      this.productSelectActive = !this.productSelectActive
      // 選後把把選擇狀態顯示出來
      this.selectProductState = event.target.innerText
    },
    setNotificationDialog (content) {
      this.showNotificationDialog = true
      this.formattedMessage = content
    },
    async computeCarbon (method, product) {
      if (method === '請選擇' || product === '請選擇') {
        this.setNotificationDialog('請先選擇碳計算種類、產品(二者皆要)，再做計算獲取碳計算結果表單')
      } else {
        await UserService.GeneralCarbonCompute(method, product, 'all').then(
          res => {
            const result = res.data
            if (result.is_err) {
              this.setNotificationDialog('計算碳相關內容出現錯誤，錯誤訊息:' + result.err)
            } else {
              this.carbonTableColumns = result.result.column_list
              this.carbonTableRows = result.result.row_list
              this.methodColumnIntro = result.result.content
              this.setNotificationDialog('選擇的碳計算相關內容成功完成計算')
            }
          }
        ).catch(err => {
          if (err.response) {
            // Request made and server responded 請求成立且回應有回來，是錯誤情況
            // 若沒有要鎖住畫面則可以註解或清除掉
            this.isLoading = false
            this.setNotificationDialog('計算碳相關內容請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
            return false
          } else if (err.request) {
            // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
            this.isLoading = false
            this.setNotificationDialog('計算碳相關內容請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
            return false
          } else {
            // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
            this.isLoading = false
            this.setNotificationDialog('計算碳相關內容請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
            return false
          }
        })
      }
    },
    async downLoadExcelDataWithProduct () {
      this.isLoading = true
      await UserService.GetCarbonParametersByProductInExcel(this.selectProductState).then(
        res => {
          // 下面這個將回傳的response轉換成URL，將response的檔案數據以Blob(這可以承接檔案或者二進檔案)
          // 要設置正確MME type: browser才能呼叫正確app來開啟(開啟app也能配合知道是什麼格式，相容性才會好)，後面charset則是設置utf-8編碼(一般這個編碼較不會出錯)
          // 若採用的是.xls則是另一種MME，有需要可以去查詢使用
          const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' })
          // 然後生成一個a 連結元素
          const link = document.createElement('a')
          // 將之前的url注入給此連結元素
          link.href = url
          // 設定此連結屬性，為下載以及值(下載名稱)
          if (this.selectProductState === '請選擇') {
            link.setAttribute('download', 'empty' + '_para_' + 'results.xlsx')
          } else {
            link.setAttribute('download', this.selectProductState + '_para_' + 'results.xlsx')
          }
          // 接著將上面處理好的原件append到body之中，並且模仿點擊事件觸發這個下載動作
          document.body.appendChild(link)
          link.click()
          // 使用完畢將link <a>清除，並且結束鎖住畫面
          document.body.removeChild(link)
          this.isLoading = false
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
    },
    async downLoadExcelCarbonComputedWithProductMethod (method, product) {
      if (method === '請選擇' || product === '請選擇') {
        this.setNotificationDialog('請先選擇碳計算種類、產品(二者皆要)，再做計算獲取碳計算結果表單')
      } else {
        this.isLoading = true
        await UserService.GetCarbonComputedByProductMethodInExcel(method, product, 'all').then(
          res => {
            // 下面這個將回傳的response轉換成URL，將response的檔案數據以Blob(這可以承接檔案或者二進檔案)
            // 要設置正確MME type: browser才能呼叫正確app來開啟(開啟app也能配合知道是什麼格式，相容性才會好)，後面charset則是設置utf-8編碼(一般這個編碼較不會出錯)
            // 若採用的是.xls則是另一種MME，有需要可以去查詢使用
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' })
            // 然後生成一個a 連結元素
            const link = document.createElement('a')
            // 將之前的url注入給此連結元素
            link.href = url
            // 設定此連結屬性，為下載以及值(下載名稱)
            link.setAttribute('download', method + '_' + product + '_' + 'results.xlsx')
            // 接著將上面處理好的原件append到body之中，並且模仿點擊事件觸發這個下載動作
            document.body.appendChild(link)
            link.click()
            // 使用完畢將link <a>清除，並且結束鎖住畫面
            document.body.removeChild(link)
            this.isLoading = false
          }
        ).catch(err => {
          // console.log('Auth err')
          if (err.response) {
            // Request made and server responded 請求成立且回應有回來，是錯誤情況
            // 若沒有要鎖住畫面則可以註解或清除掉
            this.isLoading = false
            return false
          } else if (err.request) {
            // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
            this.isLoading = false
            return false
          } else {
            // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
            this.isLoading = false
            return false
          }
        })
      }
    },
    async reloadPage () {
      this.isLoading = true
      await this.computeCarbon(this.currentSelectMethod, this.selectProductState)
      this.isLoading = false
    }
  },
  computed: {
    formattedMethodColumnIntro () {
      let result = this.methodColumnIntro
      // 沒做這個，回傳的\n、縮排\t會無效
      result = result.replace(/\t/g, '    ') // /t替換為4個空格
      result = result.replace(/\n/g, '<br>') // 替換 \n 為 HTML <br> 標籤
      // console.log(result)
      return result
    }
  }
}
</script>

<style scoped>
.custom-table-inside {
  background-color: #8b8b8b;
  height: 500px;
}
/* class scrollbar背景設置 */
.tableScrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  /*底色*/
  background: #F5F5F5;
  /*漸層變化*/
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* class scrollbar滑動controller設置 */
.tableScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 150px;
  background-color: #413960;
}
/* class scrollbar corner設置(上下與左右滑動條會有個交錯正方形空白，設置這個才能處理 */
.tableScrollbar::-webkit-scrollbar-corner{
  background: #F5F5F5;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* class scrollbar使用提示，使用拉動時候會變色 */
.tableScrollbar::-webkit-scrollbar-thumb:hover {
  background: #85d0a0;
}
</style>
